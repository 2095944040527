import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from "./services/analytics.service";
import {Subscription} from "rxjs";
import {NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent} from "ngx-cookieconsent";
import {PopupService} from "./services/popup.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
    title = 'RMNEvents';

    popups: any[] = [];
    assetsUrl: string = "";
    modalRef: BsModalRef;
    private popupOpenSubscription: Subscription;

    constructor(
        private analyticsService: AnalyticsService,
        private ccService: NgcCookieConsentService,
        private popupService: PopupService,
    ) {
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });
        this.analyticsService.init();
    }


    ngOnDestroy() {
        this.popupOpenSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.popupService.setFlag(true);
    }
}
