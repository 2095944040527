import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from "./services/analytics.service";
import {Subscription} from "rxjs";
import {NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent} from "ngx-cookieconsent";
import {Router, Routes} from "@angular/router";
import {CmsService} from "./services/cms.service";
import {HomeComponent} from "./web/home/home.component";
import {SigninComponent} from "./auth/signin/signin.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
    title = 'RMNEvents';

    private popupOpenSubscription: Subscription;

    constructor(
        private analyticsService: AnalyticsService,
        private ccService: NgcCookieConsentService,
        private cmsService: CmsService,
    ) {
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });


        this.analyticsService.init();
    }


    ngOnDestroy() {
        this.popupOpenSubscription.unsubscribe();
    }

    ngOnInit(): void {

    }
}
