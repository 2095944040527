import {environment} from "../environments/environment";

export class EventApi {
    static readonly getEvents = environment.API_URL + 'events';
    static readonly getCurrentSeason = environment.API_URL + 'current-season';
    static readonly liveEvent = environment.API_URL + 'live-event';
    static readonly eventDetail = environment.API_URL + 'events';
    static readonly getMapData = environment.API_URL + 'events/map';
    static readonly pendingRegistrations = environment.API_URL + 'pending-registrations';
    static readonly eventChat = environment.API_URL + 'events/:eventId/chats';
    static readonly eventPlacements = environment.API_URL + 'events/:eventId/placements';
    static readonly allRegistrations = environment.API_URL + 'events/:eventId/registrations';
    static readonly getPurchaseDetails = environment.API_URL + 'events/:eventId/purchases';
    static readonly questionnaire = environment.API_URL + 'events/:eventId/questionnaire';

    static readonly getPurchaseHistory = environment.API_URL + 'purchases/products';

    static readonly getAllSeasons = environment.API_URL + 'seasons'

    static readonly homePage = environment.API_URL + 'home';
    static readonly getDivisionWeightClasses = environment.API_URL + 'divisions/:divisionId/weight-classes';

    static readonly availablePopups = environment.API_URL + "popups";
    static readonly promoPage = environment.API_URL + "promo";
}

export class AuthApi {
    static readonly signUp = environment.API_URL + 'sign-up';
    static readonly verifyOTP = environment.API_URL + 'verify-otp';
    static readonly resendOTP = environment.API_URL + 'resend-otp';
    static readonly forgotPassword = environment.API_URL + 'forgot-password';
    static readonly resetPassword = environment.API_URL + 'reset-password';
    static readonly socialLogin = environment.API_URL + 'social-login';
    static readonly logout = environment.API_URL + 'logout';
    static readonly deleteAccount = environment.API_URL + 'delete-account';
}

export class UserApi {
    static readonly updateProfile = environment.API_URL + 'update-userinfo';
    static readonly changeEmail = environment.API_URL + 'change-email';
    static readonly updateEmail = environment.API_URL + 'update-email';
    static readonly getProfile = environment.API_URL + 'profile';
    static readonly changePassword = environment.API_URL + 'change-password';
    static readonly changeInitialPassword = environment.API_URL + 'change-initial-password';
    static readonly updateSettings = environment.API_URL + 'settings';
    static readonly generalChatRoom = environment.API_URL + 'general-chat/room';
    static readonly generalChat = environment.API_URL + 'general-chat/:roomId';
    static readonly markChatAsRead = environment.API_URL + 'conversations/:roomId/mark-as-read';
}

export class AthleteApi {
    static readonly my_athletes = environment.API_URL + 'athletes';
    static readonly all_athlete = environment.API_URL + 'all-athlete';
    static readonly add_athlete = environment.API_URL + 'create-athlete';
    static readonly update_athlete = environment.API_URL + 'update-athlete';
    static readonly delete_athlete = environment.API_URL + 'delete-athlete';
    static readonly received_athlete_requests = environment.API_URL + 'received-requests';
    static readonly request_athlete = environment.API_URL + 'request-athlete';
    static readonly cancel_request_athlete = environment.API_URL + 'cancel-request';
    static readonly contact_support = environment.API_URL + 'contact-support';
    static readonly accept_reject_athlete = environment.API_URL + 'accept-reject-athlete';
    static readonly removeViewAccess = environment.API_URL + 'athletes/:athleteId/delete-access';
    static readonly getViewers = environment.API_URL + 'athletes/:athleteId/viewers';
    static readonly getCoaches = environment.API_URL + 'athletes/:athleteId/coaches';
    static readonly removeViewer = environment.API_URL + 'athletes/:athleteId/viewers/remove';
    static readonly removeCoach = environment.API_URL + 'athletes/:athleteId/coaches/remove';

    static readonly registeredEvents = environment.API_URL + 'athletes/:athleteId/events';
    static readonly receivedRanks = environment.API_URL + 'athletes/:athleteId/ranks';
    static readonly receivedAwards = environment.API_URL + 'athletes/:athleteId/awards';

    static readonly getTeams = environment.API_URL + 'teams';
    static readonly changeTeam = environment.API_URL + 'athletes/:athleteId/event-team';

    static readonly grades = environment.API_URL + 'grades';
    static readonly claimAthlete = environment.API_URL + 'athletes/claim';
}

export class CMSApi {
    static readonly cmsList = environment.API_URL + 'list-cms';
    static readonly pagesList = environment.API_URL + 'list-pages';
    static readonly getCmsRules = environment.API_URL + 'rules';
    static readonly getCmsDetail = environment.API_URL + 'cms';
    static readonly faqList = environment.API_URL + 'faqs';
}

export class ContactApi {
    static readonly createContact = environment.API_URL + 'create-contact';
}

export class MembershipApi {
    static readonly  getMemberships = environment.API_URL + 'memberships';
    static readonly  purchaseMembership = environment.API_URL + 'purchase-membership';
    static readonly  membershipHistory = environment.API_URL + 'purchases/memberships';
}

export class PaymentApi {
    static readonly getCards = environment.API_URL + 'cards';
    static readonly addNewCard = environment.API_URL + 'cards/create';
    static readonly removeCard = environment.API_URL + 'cards/remove';

    static readonly couponDetails = environment.API_URL + 'coupons/:code'
}

export class RankApi {
    static readonly overAllRanks = environment.API_URL + 'rankings';
}

export class NotificationApi {
    static readonly getNotifications = environment.API_URL + 'notifications';
    static readonly unreadCount = environment.API_URL + 'notifications/unread-count';
    static readonly getEventNotifications = environment.API_URL + 'events/:eventId/notifications';
}

export class AwardApi {
    static readonly getHomePageAwards = environment.API_URL + 'home/awards';
    static readonly getUserAwards = environment.API_URL + 'my-awards';
}

export class NewsApi {
    static readonly newsList = environment.API_URL + 'news'
}

export class SettingApi{
    static readonly getSetting = environment.API_URL + 'settings/:key'
}
