import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    constructor() {
    }

    public set(key:string, data:any, expiry: Date | null = null){
        let cacheObj = {
            data: data,
            expiry: expiry
        };
        window.localStorage.setItem(key, JSON.stringify(cacheObj));
    }

    public get(key: string, defaults: any | null = null){
        const cache = window.localStorage.getItem(key);
        if (cache) {
            let data = JSON.parse(cache);
            if(data.expiry && new Date(data.expiry).getTime() < new Date().getTime()){
                return defaults;
            }
            else{
                return data.data;
            }
        }
        return defaults;
    }

    public remove(key: string){
        window.localStorage.removeItem(key);
    }

    public setEventCache(eventId: string, key: string, data:any){
        let event = this.get(eventId, {});
        event[key] = data;
        this.set(eventId, event);
    }

    public getEventCache(eventId: string, key: string | null = null){
        let event = this.get(eventId);
        if(event){
            return key ? event[key] : event;
        }
        return null;
    }

    public removeEventCache(eventId: string, key: string | null = null){
        if(!key){
            this.remove(eventId);
        }
        else{
            let event = this.get(eventId, {});
            if(event){
                event[key] = null;
                this.set(eventId, event);
            }
        }
    }
}
