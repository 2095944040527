import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EventApi} from "../../../../../const/web.url";
import {CacheService} from "./cache.service";
import {BehaviorSubject, of} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PopupService {
    visited: any[];
    readonly cacheKey: string = "visited_popups";
    private shouldShowPopup = new BehaviorSubject<boolean>(false);
    flag$ = this.shouldShowPopup.asObservable();
    constructor(
        private http: HttpClient,
        private cache: CacheService,
    ) {
    }

    setFlag(value: boolean): void {
        this.shouldShowPopup.next(value);
    }

    getAvailablePopups(){
        if(this.shouldShowPopup.value){
            this.visited = this.cache.get(this.cacheKey, []);
            return this.http.get<any>(EventApi.availablePopups, {params: {visited: this.visited.toString()}});
        }
        else{
            return of([])
        }
    }

    addVisited(popupId: string){
        this.visited.push(popupId);
        this.cache.set(this.cacheKey, this.visited);
    }
}
