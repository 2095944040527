import {environment} from "../environments/environment";

export class GlobalConstant {
    public static app_name = "RMN Events";
    public static aes_seceret = "yKRHgHsvZ4fwblHqcL1WNDuNJ3JLFLVg";
    public static aes_iv = "yKRHgHsvZ4fwblHq";

    public static resourseURL = environment.RESOURCE_URL;
    // public static appURL = environment.APP_URL;

    // event management tabs
    public static timezone = [
        {
            "name": "Hawaii–Aleutian Standard Time (HST) UTC − 10",
            "value": "US/Hawaii",
            "short_name": "HST"
        },
        {
            "name": "Alaska Standard Time (AKST) UTC − 9",
            "value": "US/Alaska",
            "short_name": "AKST"
        },
        {
            "name": "Pacific Standard Time (PST) UTC − 8",
            "value": "US/Pacific",
            "short_name": "PST"
        },
        {
            "name": "Mountain Standard Time (MST) UTC − 7",
            "value": "US/Mountain",
            "short_name": "MST"
        },
        {
            "name": "Central Standard Time (CST) UTC − 6",
            "value": "US/Central",
            "short_name": "CST"
        },
        {
            "name": "Eastern Standard Time (EST) UTC − 5",
            "value": "US/Eastern",
            "short_name": "EST"
        }
    ];

    public static readonly  event_division_type = [
        {
            "value":'boys',
            "name":"Boys"
        },{
            "value":'girls',
            "name":"Girls"
        },{
            "value":'rookie',
            "name":"Rookie"
        },{
            "value":'open',
            "name":"Open"
        }
    ];

    public static readonly divisionStyles = [
        {
            "value":'freestyle',
            "name":"Freestyle"
        },{
            "value":'folkstyle',
            "name":"Folkstyle"
        },{
            "value":'greco',
            "name":"Greco"
        },{
            "value":'duals',
            "name":"Duals"
        }
    ];

    public static event_wrestling_type = [
        {
            "value":1,
            "name":"Free style"
        },{
            "value":2,
            "name":"Folk style"
        }
    ];

    public static readonly popupTypes = [{
        value: "general",
        name: "General"
    },{
        value: "event-specific",
        name: "Event Specific"
    }]

    public static athleteMinimumAge = 4;

    public static notificationSound = "./assets/sounds/notification-drop.wav";

    public static noTeam = "No Team";

    public static roles = [{
        key: 'owner',
        name: "Owner"
    },{
        key: "admin",
        name: "Admin"
    },{
        key: 'employee',
        name: "Employee"
    },{
        key: "user",
        name: "User"
    }];

    public static productCategories = [{
        key: "floor-passes",
        name: "Floor Passes"
    },{
        key: "general-admission",
        name: "General Admission",
    },{
        key: "event-merchandise",
        name: "Event Merchandise"
    }]
}
